import {
  AutofillOptions,
  AutofillRetrieveResponse,
  AutofillSuggestion,
  AutofillSuggestionResponse,
  MapboxAutofill,
  MapboxSearch,
  Options,
  RetrieveResponse,
  SearchSession,
  Suggestion,
  SuggestionResponse
} from '@mapbox/search-js-core';
import { useMemo } from 'react';

export type SearchSessionType = SearchSession<
  Options,
  Suggestion,
  SuggestionResponse,
  RetrieveResponse
>;

export type AutofillSearchSessionType = SearchSession<
  AutofillOptions,
  AutofillSuggestion,
  AutofillSuggestionResponse,
  AutofillRetrieveResponse
>;

function useSearchSession(search: MapboxSearch): SearchSessionType;
function useSearchSession(autofill: MapboxAutofill): AutofillSearchSessionType;

/**
 * A React hook that returns a {@link SearchSession} instance.
 *
 * @param {MapboxSearch | MapboxAutofill} search
 * @returns {SearchSession}
 * @see {@link SearchSession}
 */
function useSearchSession(
  search: MapboxSearch | MapboxAutofill
): SearchSessionType | AutofillSearchSessionType {
  const searchSession = useMemo(() => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return new SearchSession(search as any);
  }, [search]);

  if (search instanceof MapboxSearch) {
    return searchSession as SearchSessionType;
  } else {
    return searchSession as AutofillSearchSessionType;
  }
}

export { useSearchSession };
